import { createStore } from 'vuex'

export default createStore({
    state: {
        basePath: 'https://pansionaty-pozhilyh.ru',
        send: false,
        sendSuccess: false
    },
    mutations: {
        changeSuccess (state, payload) {
            state.sendSuccess = payload
        }
    },
    actions: {
        async Send (context, payload) {
            if ((process.env.NODE_ENV === 'development')) {
                console.log(payload)
            } else {
                const fields = {
                    title: payload.setting.title,
                    subject: payload.setting.subject,
                    fields: payload.fields
                }

                const formData = new FormData()
                formData.append('fields', JSON.stringify(fields))

                if (payload.files !== '') {
                    for (let i = 0; i < payload.files.length; i++) {
                        let file = payload.files[i]
                        formData.append('files[' + i + ']', file)
                    }
                }

                await fetch(`/api/v1/send_form/`, {
                    method: 'POST',
                    body: formData
                })
            }
        }
    },
    modules: {
    },
    getters: {
        isSend: s => s.send,
        sendSuccess: s => s.sendSuccess
    }
})
