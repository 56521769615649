/*global $*/
/*eslint no-undef: "error"*/

export default class MenuToggle {
    classButton = 'menu-toggle'
    classButtonActive = 'menu-toggle-active'
    classHeader = 'header'
    classHeaderActive = 'header-active'
    classHeaderMobile = 'header-mobile'
    classHeaderMobileActive = 'header-mobile-active'

    constructor () {
        let self = this

        document.getElementsByClassName(self.classButton)[0].addEventListener('click', function () {
            this.classList.toggle(self.classButtonActive)
            document.querySelector('.' + self.classHeader).classList.toggle(self.classHeaderActive)

            if(document.querySelector('.' + self.classHeaderMobile) !== null) {
                document.querySelector('.' + self.classHeaderMobile).classList.toggle(self.classHeaderMobileActive)
            }

            $('html, body').animate({scrollTop: 0}, 600)
        })
    }
}
