import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs, Lazy} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs, Lazy])

export default class SwiperSlider {
    createProductsSlider () {
        return new Swiper('#products-slider', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.products-slider-next',
                prevEl: '.products-slider-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 3
                }
            }
        })
    }
    createAdvantages1Slider () {
        return new Swiper('#advantages-1-slider', {
            slidesPerView: 4,
            spaceBetween: 50,
            navigation: {
                nextEl: '.advantages-1-slider-next',
                prevEl: '.advantages-1-slider-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 40
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 40
                }
            }
        })
    }
    createReviewSlider () {
        return new Swiper('#review-slider', {
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                nextEl: '.review-slider-next',
                prevEl: '.review-slider-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 15
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20
                }
            }
        })
    }

    createReviewSliderBlock () {
        return new Swiper('#review-slider-block', {
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: '.review-slider-block-next',
                prevEl: '.review-slider-block-prev',
            },
            breakpoints: {}
        })
    }
    createReviewTextSlider () {
        return new Swiper('#review-slider-text', {
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                nextEl: '.review-slider-text-next',
                prevEl: '.review-slider-text-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 15
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20
                }
            }
        })
    }
    createReviewVideoSlider () {
        return new Swiper('#review-slider-video', {
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                nextEl: '.review-slider-video-next',
                prevEl: '.review-slider-video-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 15
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20
                }
            }
        })
    }
    createArticleSlider () {
        return new Swiper('#article-slider', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.article-slider-next',
                prevEl: '.article-slider-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 15
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            }
        })
    }
    createTariffSlider () {
        return new Swiper('#tariff-slider', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.tariff-slider-next',
                prevEl: '.tariff-slider-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 15
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            }
        })
    }
    createProductSlider () {
        let swiper = new Swiper(".mySwiper", {
            loop: false,
            preloadImages: false,
            lazy: true,
            spaceBetween: 22,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                375: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 10
                }
            }
        })

        return new Swiper(".mySwiper2", {
            loop: false,
            preloadImages: false,
            lazy: true,
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            thumbs: {
                swiper: swiper,
            }
        })
    }
    createDaySlider () {
        return new Swiper('#day-slider', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.day-slider-next',
                prevEl: '.day-slider-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 15
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            }
        })
    }

    createBestSlider () {
        return new Swiper('#best-slider', {
            slidesPerView: 5,
            spaceBetween: 15,
            navigation: {
                nextEl: '.best-slider-next',
                prevEl: '.best-slider-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                320: {
                    slidesPerView: 1,
                },
                360: {
                    slidesPerView: 1,
                },
                375: {
                    slidesPerView: 1,
                },
                414: {
                    slidesPerView: 2,
                },
                480: {
                    slidesPerView: 2,
                },
                514: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 3,
                },
                991: {
                    slidesPerView: 4,
                },
                1024: {
                    slidesPerView: 4,
                },
                1240: {
                    slidesPerView: 5,
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('products-slider')!= null) {
            this.createProductsSlider()
        }

        if(document.getElementById('advantages-1-slider')!= null) {
            this.createAdvantages1Slider()
        }

        if(document.getElementById('review-slider')!= null) {
            this.createReviewSlider()
        }

        if(document.getElementById('review-slider-block')!= null) {
            this.createReviewSliderBlock()
        }

        if(document.getElementById('review-slider-text')!= null) {
            this.createReviewTextSlider()
        }

        if(document.getElementById('review-slider-video')!= null) {
            this.createReviewVideoSlider()
        }

        if(document.getElementById('article-slider')!= null) {
            this.createArticleSlider()
        }

        if(document.getElementById('tariff-slider')!= null) {
            this.createTariffSlider()
        }

        if(document.querySelector('.mySwiper2')!= null) {
            this.createProductSlider()
        }

        if(document.getElementById('day-slider')!= null) {
            this.createDaySlider()
        }

        if(document.getElementById('best-slider')!= null) {
            this.createBestSlider()
        }
    }
}
