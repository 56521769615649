<template lang="pug">
.form-popup(v-if="show")
  div(v-show="!sendSuccess")
    .h2 Подобрать пансионат
    .form-popup__fields
      .form-popup__field
        .input-text
          input.input-text__input(
            type="text"
            placeholder="Ваше имя *"
            v-model="fieldName"
            :class="{'input-text__input-error': fieldError && v$.fieldName.$invalid, 'input-text__input-complete': !v$.fieldName.$invalid && (fieldName.length > 0)}"
          )
      .form-popup__field
        .input-text
          input.input-text__input(
            type="tel"
            placeholder="Ваш телефон *"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
            v-model="fieldPhone"
            :class="{'input-text__input-error': fieldError && v$.fieldPhone.$invalid, 'input-text__input-complete': !v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
          )
      .form-popup__field
        .input-textarea
          textarea.input-textarea__input(v-model="fieldMessage" placeholder="Комментарий")

    .form-popup__checkeds
      .form__middle
        .form__middle-item
          p.bold.none-mb Состояние здоровья
          .form__checkeds
            .input-check
              input.input-check__input(type="checkbox" value="Самостоятельно передвигается" id="form-checkbox-book-1-1" v-model="fieldCheckbox1")
              label.input-check__label(for="form-checkbox-book-1-1") Самостоятельно передвигается
            .input-check
              input.input-check__input(type="checkbox" value="Инвалид колясочник" id="form-checkbox-book-1-2" v-model="fieldCheckbox1")
              label.input-check__label(for="form-checkbox-book-1-2") Инвалид колясочник
            .input-check
              input.input-check__input(type="checkbox" value="Лежачий" id="form-checkbox-book-1-3" v-model="fieldCheckbox1")
              label.input-check__label(for="form-checkbox-book-1-3") Лежачий

        .form__middle-item
          p.bold.none-mb Пол
          .form__checkeds
            .input-check
              input.input-check__input(type="checkbox" value="Мужской" id="form-checkbox-book-2-1" v-model="fieldCheckbox2")
              label.input-check__label(for="form-checkbox-book-2-1") Мужской
            .input-check
              input.input-check__input(type="checkbox" value="Женский" id="form-checkbox-book-2-2" v-model="fieldCheckbox2")
              label.input-check__label(for="form-checkbox-book-2-2") Женский
    .consent
      input.consent__input(type="checkbox" id="form-checkbox-book" v-model="consent" value="consent" checked="checked")
      label.consent__label(for="form-checkbox-book" v-html="formConsentText")

    .form-popup__button
      .button(@click="getSend()")
        .button__text Бронировать

  div(v-show="sendSuccess")
    .h3(v-html="formSuccessTitle")
    p(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormPopupBook',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      show: false,
      sendSuccess: false,
      files: '',
      consent: ['consent'],
      fieldName: '',
      fieldPhone: '',
      fieldCheckbox1: [],
      fieldCheckbox2: [],
      fieldMessage: '',
      fieldError: false,
      formRedirect: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.send = !this.send
        this.sendSuccess = !this.sendSuccess
        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Телефон',
              value: this.fieldPhone
            },
            {
              title: 'Состояние здоровья',
              value: this.fieldCheckbox1.join(', ')
            },
            {
              title: 'Пол',
              value: this.fieldCheckbox2.join(', ')
            },
            {
              title: 'Сообщение',
              value: this.fieldMessage
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = this.fieldMessage = ''
        this.fieldCheckbox1 = this.fieldCheckbox2 = []
        this.fieldError = false

        if (this.formRedirect) {
          setTimeout(() => {
            window.location.href = this.formRedirect
          }, 1500)
        } else {
          setTimeout(() => {
            this.send = !this.send
            this.sendSuccess = !this.sendSuccess
          }, 4000)
        }
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  },

  created () {
    if(document.querySelector('[data-src="#block-popup-book"]') !== null) {
      document.querySelector('[data-src="#block-popup-book"]').addEventListener('click', () => {
        this.show = true
      })
    }

    this.formRedirect = window.appPansionat.form.formRedirect
  }
}
</script>
