<template lang="pug">
.form-popup.form-popup-review(v-if="show")
  div(v-show="!sendSuccess")
    .h2.center Оставить отзыв
    .form-popup__fields
      .form-popup__field
        .input-text
          input.input-text__input(
            type="text"
            placeholder="Ваше имя *"
            v-model="fieldName"
            :class="{'input-text__input-error': fieldError && v$.fieldName.$invalid, 'input-text__input-complete': !v$.fieldName.$invalid && (fieldName.length > 0)}"
          )
      .form-popup__field
        .input-text
          input.input-text__input(
            type="email"
            placeholder="E-mail"
            v-model="fieldEmail"
          )
      .form-popup__field
        .input-textarea
          textarea.input-textarea__input(
            v-model="fieldMessage"
            placeholder="Ваш отзыв *"
            :class="{'input-textarea__input-error': fieldError && v$.fieldMessage.$invalid, 'input-textarea__input-complete': !v$.fieldMessage.$invalid && (fieldMessage.length > 0)}"
          )

    .form-popup__consent
      .consent
        input.consent__input(type="checkbox" id="form-checkbox-book" v-model="consent" value="consent" checked="checked")
        label.consent__label(for="form-checkbox-book" v-html="formConsentText")

    .form-popup__button
      .button(@click="getSend()")
        .button__text Отправить

  div(v-show="sendSuccess")
    .h3(v-html="formSuccessTitle")
    p(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormPopupReview',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      show: false,
      sendSuccess: false,
      files: '',
      consent: ['consent'],
      fieldName: '',
      fieldEmail: '',
      fieldMessage: '',
      fieldError: false,
      formRedirect: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.send = !this.send
        this.sendSuccess = !this.sendSuccess
        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'E-mail',
              value: this.fieldEmail
            },
            {
              title: 'Отзыв',
              value: this.fieldMessage
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldEmail = this.fieldMessage = ''
        this.fieldError = false

        if (this.formRedirect) {
          setTimeout(() => {
            window.location.href = this.formRedirect
          }, 1500)
        } else {
          setTimeout(() => {
            this.send = !this.send
            this.sendSuccess = !this.sendSuccess
          }, 4000)
        }
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldMessage: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(400)
      },

      consent: {
        required
      }
    }
  },

  created () {
    if(document.querySelector('[data-src="#block-popup-review"]') !== null) {
      document.querySelector('[data-src="#block-popup-review"]').addEventListener('click', () => {
        this.show = true
      })
    }

    this.formRedirect = window.appPansionat.form.formRedirect
  }
}

</script>
