/*global $*/
/*eslint no-undef: "error"*/

export default class FilterCheck {
    classItem = 'filter-check--item'
    classButton = 'filter-check--button'

    classInput = 'input-checked__input'
    classHidden = 'input-checked-hidden'

    constructor () {
        let self = this
        let objItem = $(`.${self.classButton}`)
        let curBlock = objItem.closest(`.${self.classItem}`).data('block')
        let objField = $(`input[name='${curBlock}']`)

        if (parseInt(objField.length) === 1) {
            objItem.closest(`.${self.classItem}`).removeClass(self.classHidden)
            objItem.closest(`.${self.classItem}`).find(`.${self.classInput}`).prop('checked', objField.is(':checked'))

            objItem.click(function () {
                objField.click()
            })
        }
    }
}
