/*global $*/
/*eslint no-undef: "error"*/
import Cookies from 'js-cookie'
export default class IconBlock {
    cookieName = 'cookie-pansionat--icon'

    classList = 'icon-block--list'
    classValue = 'icon-block--value'
    classButton = 'icon-block--button'
    classActive = 'active'

    constructor () {
        let self = this
        let objButton = $(`.${self.classButton}`)
        let objList = $(`.${self.classList}`)
        let objValue = objList.find(`.${self.classValue}`)

        // выводим общее кол-во
        if (Cookies.get(self.cookieName)) {
            let result = JSON.parse(Cookies.get(self.cookieName))

            if (result.length > 0) {
                objList.toggleClass(self.classActive)
                objValue.text(result.length)
            }
        }

        // добавляем активность, если сущность выбрана
        objButton.each((key, item) => {
            let result = []
            let type = $(item).data('type')
            let id = $(item).data('id')

            if (Cookies.get(self.cookieName)) {
                result = JSON.parse(Cookies.get(self.cookieName))
            }

            const inActive = result.some(item => {
                return (item.id === id) && (item.type === type)
            })

            if (inActive) {
                $(item).addClass(self.classActive)
            }
        })

        $(`body`).on('click', `.${self.classButton}`, function () {
            let result = []
            let type = $(this).data('type')
            let id = $(this).data('id')
            let inArray = false
            console.log(id)

            $(this).toggleClass(self.classActive)

            if (Cookies.get(self.cookieName)) {
                result = JSON.parse(Cookies.get(self.cookieName))
                inArray = result.some(item => {
                    return (item.id === id) && (item.type === type)
                })
            }

            if (!inArray) {
                result.push({
                    id: id,
                    type: type
                })
            } else {
                if (!$(this).hasClass(self.classActive)) {
                    result = result.filter(item => {
                        return !((item.id === id) && (item.type === type))
                    })
                }
            }

            // выводим общее кол-во
            if (result.length > 0) {
                objList.addClass(self.classActive)
                objValue.text(result.length)
            } else {
                objList.removeClass(self.classActive)
            }

            // сохраняем cookie
            Cookies.set(self.cookieName, JSON.stringify(result), { expires: 60 })

            console.log(result)
        })
    }
}
