<template lang="pug">
.form-line
  .form-line__fields
    .form-line__field
      .input-text
        input.input-text__input(
          type="text"
          placeholder="Ваше имя *"
          v-model="fieldName"
          :class="{'input-text__input-error': fieldError && v$.fieldName.$invalid, 'input-text__input-complete': !v$.fieldName.$invalid && (fieldName.length > 0)}"
        )
    .form-line__field
      .input-text
        input.input-text__input(
          type="tel"
          placeholder="Ваш телефон *"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          v-model="fieldPhone"
          :class="{'input-text__input-error': fieldError && v$.fieldPhone.$invalid, 'input-text__input-complete': !v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
        )
    .form-line__field
      Multiselect(
        placeholder="Особенности"
        v-model="fieldValue3"
        :options="fieldOptions3"
      )
    .form-line__field
      .button.button-pink(@click="getSend()")
        .button__text Подобрать варианты

  .form-line__consent
    .consent
      input.consent__input(type="checkbox" id="form-checkbox-section" v-model="consent" value="consent" checked="checked")
      label.consent__label(for="form-checkbox-section" v-html="formConsentText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import Multiselect from '@vueform/multiselect'

export default {
  name: 'AppFormSelection',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  components: {
    Multiselect,
  },

  data() {
    return {
      send: false,
      sendSuccess: false,
      files: '',
      fieldName: '',
      fieldPhone: '',
      fieldValue3: null,
      fieldOptions3: [
        'Wi-Fi',
        'Анимация и досуг',
        'Ванна, душ',
        'Забота 24/7',
        'Кондиционер'
      ],
      consent: ['consent'],
      fieldError: false,
      formRedirect: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.send = !this.send
        this.sendSuccess = !this.sendSuccess
        document.querySelector('#button-popup-success').click()
        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Телефон',
              value: this.fieldPhone
            },
            {
              title: 'Особенности',
              value: this.fieldValue3
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''
        this.fieldError = false

        if (this.formRedirect) {
          setTimeout(() => {
            window.location.href = this.formRedirect
          }, 1500)
        } else {
          setTimeout(() => {
            this.send = !this.send
            this.sendSuccess = !this.sendSuccess
          }, 4000)
        }
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  },

  created () {
    this.formRedirect = window.appPansionat.form.formRedirect
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
