export default class SectionsIndex {
    classBlock = 'section-item'

    classItem = 'section-item__more-wrapper'
    classItemOpen = 'section-item__more-wrapper-open'

    classLinks = 'section-item__items'
    classLink = 'section-item__link'
    classLinkHidden = 'section-item__link-hidden'

    constructor () {
        let self = this

        if(document.querySelector('.' + self.classItem) != null) {
            document.querySelectorAll('.' + self.classItem).forEach((button) => {
                button.addEventListener('click', function () {
                    button.classList.toggle(self.classItemOpen)
                    let show = button.classList.contains(self.classItemOpen)
                    let count = button.getAttribute('data-count')
                    button.textContent = (show) ? button.getAttribute('data-do') : button.getAttribute('data-to')

                    button.closest(`.${self.classBlock}`).querySelectorAll(`.${self.classLink}`).forEach((item, key) => {
                        if (show) {
                            item.classList.remove(self.classLinkHidden)
                        } else {
                            if (key >= count) {
                                item.classList.add(self.classLinkHidden)
                            }
                        }
                    })
                })
            })
        }

    }
}
