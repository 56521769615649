<template lang="pug">
.form-popup(v-if="show")
  div(v-show="!sendSuccess")
    .h2 Заказать звонок
    .form-popup__fields
      .form-popup__field
        .input-text
          input.input-text__input(
            type="text"
            placeholder="Ваше имя *"
            v-model="fieldName"
            :class="{'input-text__input-error': fieldError && v$.fieldName.$invalid, 'input-text__input-complete': !v$.fieldName.$invalid && (fieldName.length > 0)}"
          )
      .form-popup__field
        .input-text
          input.input-text__input(
            type="tel"
            placeholder="Ваш телефон *"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
            v-model="fieldPhone"
            :class="{'input-text__input-error': fieldError && v$.fieldPhone.$invalid, 'input-text__input-complete': !v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
          )

    .consent
      input.consent__input(type="checkbox" id="form-checkbox-phone" v-model="consent" value="consent" checked="checked")
      label.consent__label(for="form-checkbox-phone" v-html="formConsentText")

    .form-popup__button
      .form-popup__button-wrapper
        .button(@click="getSend()")
          .button__text Отправить

  div(v-show="sendSuccess")
    .h3(v-html="formSuccessTitle")
    p(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormPopupPhone',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      show: false,
      sendSuccess: false,
      files: '',
      consent: ['consent'],
      fieldName: '',
      fieldPhone: '',
      fieldError: false,
      formRedirect: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        console.log(this.formRedirect)
        this.send = !this.send
        this.sendSuccess = !this.sendSuccess
        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Телефон',
              value: this.fieldPhone
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''
        this.fieldError = false

        if (this.formRedirect) {
          setTimeout(() => {
            window.location.href = this.formRedirect
          }, 1500)
        } else {
          setTimeout(() => {
            this.send = !this.send
            this.sendSuccess = !this.sendSuccess
          }, 4000)
        }

      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  },

  created () {
    if(document.querySelector('[data-src="#block-popup-phone"]') != null) {
      document.querySelectorAll('[data-src="#block-popup-phone"]').forEach((button) => {
        button.addEventListener('click',  () => {
          this.show = true
        })
      })
    }

    this.formRedirect = window.appPansionat.form.formRedirect
  }
}
</script>
