<template lang="pug">
.form-success
  .h3 Сообщение отправлено
  p Ваше обращение принято, скоро с вами свяжется наш менеджер.
</template>

<script>
export default {
  name: "AppFormSuccess"
}
</script>
