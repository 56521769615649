export default class Tags {
    classButton = 'tags-item-more'
    classButtonShow = 'tags-item-more-show'

    classTagList = 'tags-list'
    classTagItem = 'tags-item'
    classTagItemHidden = 'tags-item-hidden'

    constructor () {
        let self = this

        if(document.querySelector('.' + self.classButton) != null) {
            document.querySelectorAll('.' + self.classButton).forEach((button) => {
                button.addEventListener('click', function () {
                    button.classList.toggle(self.classButtonShow)
                    let show = button.classList.contains(self.classButtonShow)
                    let count = button.getAttribute('data-count')
                    button.querySelector('.tags-item__name').textContent = (show) ? button.getAttribute('data-do') : button.getAttribute('data-to')

                    button.closest(`.${self.classTagList}`).querySelectorAll(`.${self.classTagItem}`).forEach((item, key) => {
                        if (!item.classList.contains(self.classButton)) {
                            if (show) {
                                item.classList.remove(self.classTagItemHidden)
                            } else {
                                if (key >= count) {
                                    item.classList.add(self.classTagItemHidden)
                                }
                            }
                        }
                    })
                })
            })
        }
    }
}
