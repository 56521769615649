export default class MenuActive {
    constructor () {
        let host = location.protocol + '//' + location.host + '/'
        let current = location.href.replace(host, '')

        if(document.querySelector(`[href="${current}"]`) != null) {
            document.querySelectorAll(`[href="${current}"]`).forEach((menu) => {
                menu.classList.add('active')
            })

        }
    }
}
