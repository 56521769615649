/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from './assets/js/Swiper/Swiper'
import tippy from 'tippy.js'
import AppFormSuccess from './components/Form/FormPopup/AppFormSuccess'
import AppFormMain from './components/Form/Form/AppFormMain'
import AppFormSelection from './components/Form/Form/AppFormSelection'
import AppFormCatalog from './components/Form/Form/AppFormCatalog.vue'
import AppFormPopupPhone from './components/Form/FormPopup/AppFormPopupPhone'
import AppFormPopupBook from './components/Form/FormPopup/AppFormPopupBook'
import AppFormPopupReview from './components/Form/FormPopup/AppFormPopupReview.vue'
import AppHeaderMobile from './components/Header/AppHeaderMobile'
import form from './store/form'
import FaqItem from './assets/js/Faq/FaqItem'
import MenuToggle from './assets/js/Menu/MenuToggle'
import MenuActive from './assets/js/Menu/MenuActive'
import MenuProduct from './assets/js/Menu/MenuProduct'
import MapYandex from './assets/js/Map/MapYandex'
import FilterButtons from './assets/js/Filter/FilterButtons'
import YandexMap from './assets/js/Yandex/YandexMap'
import Tags from './assets/js/Tags/Tags'
import TagsProduct from './assets/js/Tags/TagsProduct'
import SectionsIndex from './assets/js/Sections/SectionsIndex'
import FieldSelect from './assets/js/FieldSelect/FieldSelect'
import HeaderFixed from './assets/js/HeaderFixed/HeaderFixed'
import ShowHidden from './assets/js/ShowHidden/ShowHidden'
import CookieFixedBlock from './assets/js/Cookie/CookieFixedBlock/CookieFixedBlock'
import IconBlock from './assets/js/Cookie/IconBlock/IconBlock'
import VideoImg from './assets/js/Video/VideoImg/VideoImg'
import FilterCheck from './assets/js/Filter/Check/FilterCheck'
import Ref from './assets/js/Ref/Ref'
import PriceList from './assets/js/Price/List/PriceList'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    new SwiperSlider()
    new FaqItem()
    new MenuToggle()
    new MenuActive()
    new MenuProduct()
    new MapYandex()
    new FilterButtons()
    new YandexMap()
    new Tags()
    new TagsProduct()
    new SectionsIndex()
    new FieldSelect()
    new HeaderFixed()
    new ShowHidden()
    new CookieFixedBlock()
    new IconBlock()
    new VideoImg()
    new FilterCheck()
    new Ref()
    new PriceList()

    Fancybox.bind('[data-fancybox]')
    tippy('.button-icon-content', {
        theme: 'light',
        arrow: true,
        placement: 'top-start',
        animation: 'scale-extreme',
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true
    })

    tippy('.button-icon-content-bottom', {
        theme: 'light',
        arrow: true,
        placement: 'bottom',
        animation: 'scale-extreme',
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true
    })

    if(document.getElementById('block-popup-success')!= null) {
        createApp(AppFormSuccess).use(form).mount('#block-popup-success')
    }

    if(document.getElementById('form-main')!= null) {
        createApp({})
            .component('block-main', AppFormMain)
            .use(Maska)
            .use(form)
            .mount('#form-main')
    }

    if(document.getElementById('form-selection')!= null) {
        createApp({})
            .component('block-selection', AppFormSelection)
            .use(Maska)
            .use(form)
            .mount('#form-selection')
    }

    if(document.getElementById('form--catalog')!= null) {
        // createApp(AppFormCatalog).use(form).use(Maska).mount('#form--catalog')
        createApp({})
            .component('block-catalog', AppFormCatalog)
            .use(Maska)
            .use(form)
            .mount('#form--catalog')
    }

    if(document.getElementById('block-popup-phone')!= null) {
        createApp({})
            .component('block-popup-phone', AppFormPopupPhone)
            .use(Maska)
            .use(form)
            .mount('#block-popup-phone')
    }

    if(document.getElementById('block-popup-book')!= null) {
        createApp({})
            .component('block-popup-book', AppFormPopupBook)
            .use(Maska)
            .use(form)
            .mount('#block-popup-book')
    }

    if(document.getElementById('block-popup-review')!= null) {
        // createApp(AppFormPopupReview).use(form).use(Maska).mount('#block-popup-review')
        createApp({})
            .component('block-popup-review', AppFormPopupReview)
            .use(Maska)
            .use(form)
            .mount('#block-popup-review')
    }

    if(document.getElementById('block-header-mobile')!= null) {
        createApp(AppHeaderMobile).mount('#block-header-mobile')
    }

    $(document).on('mse2_load', function(e, data) {
        if(document.getElementById('form--catalog')!= null) {
            // createApp(AppFormCatalog).use(form).use(Maska).mount('#form--catalog')
            createApp({})
                .component('block-catalog', AppFormCatalog)
                .use(Maska)
                .use(form)
                .mount('#form--catalog')
        }
    })
})

document.addEventListener('mse2_load', (e, data) => {
    console.log(e, data)
})
