/*global $*/
/*eslint no-undef: "error"*/

export default class TagsProduct {
    classButton = 'tag-more'
    classButtonItem = 'tags__item-more'
    classButtonActive = 'tag-more-active'
    classList = 'tags'
    classItem = 'tags__item'
    classItemHidden = 'tags__item-hidden'
    className = 'tag__name'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let textTo = $(this).data('to')
            let textDo = $(this).data('do')

            $(this).toggleClass(self.classButtonActive)

            if ($(this).hasClass(self.classButtonActive)) {
                $(this).closest(`.${self.classList}`).find(`.${self.classItem}`).removeClass(self.classItemHidden)
                $(this).find(`.${self.className}`).text(textDo)
            } else {
                let count = $(this).data('count')
                $(this).find(`.${self.className}`).text(textTo)

                $(this).closest(`.${self.classList}`).find(`.${self.classItem}`).each((key, item) => {
                    if ((key >= count) && (!$(item).hasClass(self.classButtonItem))) {
                        $(item).addClass(self.classItemHidden)
                    }
                })
            }
        })
    }
}
