/*global $*/
/*eslint no-undef: "error"*/

export default class FieldSelect {
    classSelect = 'select'
    classSelectOpen = 'select-open'
    classTop = 'select__top'
    classItem = 'select__item'
    classItemActive = 'select__item-active'
    classValue = 'select__value'
    classInput = 'select__input'

    constructor () {
        let self = this

        // раскрываем список
        $(document).on('click', `.${self.classTop}`, function () {
            let optionCurrent = $(this).data('value')

            if (!$(this).closest(`.${self.classSelect}`).hasClass(self.classSelectOpen)) {
                $(`.${self.classSelect}`).removeClass(self.classSelectOpen)
            }

            $(this).closest(`.${self.classSelect}`).toggleClass(self.classSelectOpen)

            // выделяем активный элемент
            $(this).closest(`.${self.classSelect}`).find(`.${self.classItem}`).each((key, item) => {
                if ($(item).data('value') === optionCurrent) {
                    $(item).addClass(self.classItemActive)
                } else {
                    $(item).removeClass(self.classItemActive)
                }
            })
        })

        // выбираем значение
        $(document).on('click', `.${self.classItem}`, function () {
            let optionValue = $(this).data('value')
            let nameFiled = $(this).closest(`.${self.classSelect}`).data('name')

            // добавляем имя input если оно пустое
            if ($(this).closest(`.${self.classSelect}`).find(`.${self.classInput}`).attr('name') === '') {
                $(this).closest(`.${self.classSelect}`).find(`.${self.classInput}`).attr('name', nameFiled)
            }

            $(this).closest(`.${self.classSelect}`).find(`.${self.classTop}`).data('value', optionValue)
            $(this).closest(`.${self.classSelect}`).find(`.${self.classValue}`).text(optionValue)
            $(this).closest(`.${self.classSelect}`).find(`.${self.classInput}`).val(optionValue)
            $(this).closest(`.${self.classSelect}`).removeClass(self.classSelectOpen)
        })
    }
}
