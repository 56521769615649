<template lang="pug">
.form
  .form__top
    .input-text
      input.input-text__input(
        type="text"
        placeholder="Ваше имя *"
        v-model="fieldName"
        :class="{'input-text__input-error': fieldError && v$.fieldName.$invalid, 'input-text__input-complete': !v$.fieldName.$invalid && (fieldName.length > 0)}"
      )
    .input-text
      input.input-text__input(
        type="tel"
        placeholder="Ваш телефон *"
        v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        v-model="fieldPhone"
        :class="{'input-text__input-error': fieldError && v$.fieldPhone.$invalid, 'input-text__input-complete': !v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
      )
  .form__middle
    .form__middle-item
      p.none-mb Состояние здоровья
      .form__checkeds
        .input-check
          input.input-check__input(type="checkbox" value="Самостоятельно передвигается" id="form-checkbox-1-1" v-model="fieldCheckbox1")
          label.input-check__label(for="form-checkbox-1-1") Самостоятельно передвигается
        .input-check
          input.input-check__input(type="checkbox" value="Инвалид колясочник" id="form-checkbox-1-2" v-model="fieldCheckbox1")
          label.input-check__label(for="form-checkbox-1-2") Инвалид колясочник
        .input-check
            input.input-check__input(type="checkbox" value="Лежачий" id="form-checkbox-1-3" v-model="fieldCheckbox1")
            label.input-check__label(for="form-checkbox-1-3") Лежачий

    .form__middle-item
        p.none-mb Пол
        .form__checkeds
          .input-check
            input.input-check__input(type="checkbox" value="Мужской" id="form-checkbox-2-1" v-model="fieldCheckbox2")
            label.input-check__label(for="form-checkbox-2-1") Мужской
          .input-check
              input.input-check__input(type="checkbox" value="Женский" id="form-checkbox-2-2" v-model="fieldCheckbox2")
              label.input-check__label(for="form-checkbox-2-2") Женский


  .form__bottom
    .form__bottom-item
      .button(@click="getSend()")
        .button__text Подобрать дом<br>для престарелых
    .form__bottom-item
          .consent
            input.consent__input(type="checkbox" id="form-checkbox-main" v-model="consent" value="consent" checked="checked")
            label.consent__label(for="form-checkbox-main" v-html="formConsentText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import Multiselect from "@vueform/multiselect";

export default {
  name: 'AppFormMain',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  components: {
    Multiselect,
  },

  data() {
    return {
      send: false,
      files: '',
      consent: ['consent'],
      fieldName: '',
      fieldPhone: '',
      fieldCheckbox1: [],
      fieldCheckbox2: [],
      fieldError: false,
      formRedirect: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.send = !this.send
        document.querySelector('#button-popup-success').click()
        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Телефон',
              value: this.fieldPhone
            },
            {
              title: 'Состояние здоровья',
              value: this.fieldCheckbox1.join(', ')
            },
            {
              title: 'Пол',
              value: this.fieldCheckbox2.join(', ')
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''
        this.fieldCheckbox1 = this.fieldCheckbox2 = []
        this.fieldError = false

        if (this.formRedirect) {
          setTimeout(() => {
            window.location.href = this.formRedirect
          }, 1500)
        } else {
          setTimeout(() => {
            this.send = !this.send
          }, 4000)
        }
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  },

  created () {
    this.formRedirect = window.appPansionat.form.formRedirect
  }
}
</script>
