<template lang="pug">
.header-mobile.header-mobile-active(v-if="show")
  .page__wrapper
    .header-mobile__wrapper
      .header-mobile__menu
        .menu-mobile
          .menu-mobile__item(
            v-for="(menu, key) of menuList"
            :key="key"
            :class="{'menu-mobile__item-open': activeKey === key}"
          )
            .menu-mobile__link
              a.menu-mobile__name(
                v-html="menu.name"
                :href="menu.link"
              )

              .menu-mobile__button(
                v-if="menu.sub.length > 0"
                @click="isActiveKey(key)"
              )
                svg.menu-mobile__icon
                  use(xlink:href="#button-arrow")

            .menu-mobile__sub(v-if="menu.sub.length > 0")
              .menu-mobile-sub
                a.menu-mobile-sub__link(
                  v-for="(sub, keySub) of menu.sub"
                  :key="keySub"
                  :href="sub.link"
                  v-html="sub.name"
                )

      .header-mobile__button
        .header-mobile__button-wrapper
          .button(@click="showForm()")
            .button__text(v-html="settings.button.name")
</template>

<script>
export default {
  name: 'AppHeaderMobile',

  data () {
    return {
      show: false,
      activeKey: -1,
      settings: {},
      menuList: {}
    }
  },

  methods: {
     showForm () {
       document.querySelector('[data-src="#block-popup-phone"]').click()
     },

    isActiveKey (keyActive) {
       if (this.activeKey === keyActive) {
         this.activeKey = -1
       } else {
         this.activeKey = keyActive
       }
    }
  },

  created () {
    this.settings = window.appPansionat.arHederMobile.settings
    this.menuList = window.appPansionat.arHederMobile.menu
  },

  mounted () {
    document.getElementsByClassName('menu-toggle')[0].addEventListener('click', () => {
      this.show = true
    })
  }
}
</script>
