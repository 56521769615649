/*global $*/
/*eslint no-undef: "error"*/

export default class CookieFixedBlock {
    classKey = 'cookie-pansionat--fixed-block'

    classButton = 'fixed-block--button'
    classCookie = 'fixed-block--block'
    classCookieOpen = 'fixed-block-open'

    constructor () {
        let self = this

        if (localStorage.getItem(self.classKey) === null) {
            setTimeout(() => {
                $(`.${self.classCookie}`).addClass(self.classCookieOpen)
                localStorage.setItem(self.classKey, 'da')
            }, 5000)
        }

        $(`.${self.classButton}`).click(function () {
            localStorage.setItem(self.classKey, 'da')
            $(`.${self.classCookie}`).removeClass(self.classCookieOpen)
        })
    }
}
