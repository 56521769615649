/*global $*/
/*eslint no-undef: "error"*/

export default class ShowHidden {
    classBlock = 'show-hidden'
    classBlockShow = 'show-hidden-show'
    classButton = 'show-hidden__bottom-wrapper'
    classButtonText = 'show-hidden__button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockShow)

            if ($(this).closest(`.${self.classBlock}`).hasClass(self.classBlockShow)) {
                $(this).find(`.${self.classButtonText}`).text($(this).find(`.${self.classButtonText}`).data('hidden'))
            } else {
                $(this).find(`.${self.classButtonText}`).text($(this).find(`.${self.classButtonText}`).data('show'))
            }
        })
    }
}
