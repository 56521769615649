/*global $*/
/*eslint no-undef: "error"*/

export default class YandexMap {
    classButton = 'product-sort__map'
    classButtonShow = 'product-sort__map-show'
    classMap = 'yandex-map'
    classMapHidden = 'yandex-map-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classButtonShow)
            $(`.${self.classMap}`).toggleClass(self.classMapHidden)
        })
    }
}
