/*global $*/
/*eslint no-undef: "error"*/
export default class PriceList {
    classItem = 'price-list--item'
    classButton = 'price-list--button'
    classButtonActive = 'price-list__button---active'
    classBlock = 'price-list--block'
    classBlockActive = 'price-list__item---active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}[data-block='${block}']`).addClass(self.classButtonActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}`).removeClass(self.classBlockActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}[data-block='${block}']`).addClass(self.classBlockActive)
        })
    }
}
