export default class MapYandex {
    constructor () {
        let map_container = document.getElementById('map_container')

        if (map_container !== null) {
            let options_map = {
                once: true,//запуск один раз, и удаление наблюдателя сразу
                passive: true,
                capture: true
            }
            map_container.addEventListener('click', start_lazy_map, options_map)
            map_container.addEventListener('mouseover', start_lazy_map, options_map)
            map_container.addEventListener('touchstart', start_lazy_map, options_map)
            map_container.addEventListener('touchmove', start_lazy_map, options_map)
        }

        let map_loaded = false
        function start_lazy_map() {
            if ((!map_loaded) && (map_container)) {
                let map_block = document.getElementById('ymap_lazy')
                map_loaded = true
                map_block.setAttribute('src', map_block.getAttribute('data-src'))
                map_block.removeAttribute('data_src')
                console.log('YMAP LOADED')
            }
        }
    }
}
