export default class FilterButtons {
    classFilter = 'filter'
    classFilterOpen = 'filter-open'
    classFilterShow = 'filter-show'

    classButtonItem = 'filter__title'
    classButtonItemHidden = 'filter__title-hidden'

    classMore = 'filter__more-wrapper'
    classMoreAll = 'filter__more-wrapper-all'

    classProps = 'filter__props'
    classProp = 'filter__prop'
    classPropHidden = 'filter__prop-hidden'

    constructor () {
        let self = this

        if(document.querySelector('.' + self.classFilterShow) != null) {
            document.querySelectorAll('.' + self.classFilterShow).forEach((button) => {
                button.addEventListener('click', function () {
                    document.querySelector('.' + self.classFilter).classList.toggle(self.classFilterOpen)
                })
            })
        }

        if(document.querySelector('.' + self.classButtonItem) != null) {
            document.querySelectorAll('.' + self.classButtonItem).forEach((button) => {
                button.addEventListener('click', function () {
                    this.classList.toggle(self.classButtonItemHidden)
                })
            })
        }

        if(document.querySelector('.' + self.classMore) != null) {
            document.querySelectorAll('.' + self.classMore).forEach((button) => {
                button.addEventListener('click', function () {
                    button.classList.toggle(self.classMoreAll)
                    let buttonAll = button.classList.contains(self.classMoreAll)

                    if (buttonAll) {
                        button.textContent = button.getAttribute('data-do')
                    } else {
                        button.textContent = button.getAttribute('data-to')
                    }

                    button.closest(`.${self.classProps}`).querySelectorAll(`.${self.classProp}`).forEach((prop, key) => {
                        if (buttonAll) {
                            prop.classList.remove(self.classPropHidden)
                        } else {
                            if (key > 4) {
                                prop.classList.add(self.classPropHidden)
                            }
                        }
                    })
                })
            })
        }
    }
}
