export default class FaqItem {
    classItem = 'faq-item'
    classItemOpen = 'faq-item-open'

    constructor () {
        let self = this

        if(document.querySelector('.' + self.classItem) != null) {
            document.querySelectorAll('.' + self.classItem).forEach((button) => {
                button.addEventListener('click', function () {
                    this.classList.toggle(self.classItemOpen)
                })
            })
        }

    }
}
