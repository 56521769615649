<template lang="pug">
.form-catalog-form
    .form-catalog-form__fields
      .input-text
        input.input-text__input(
            type="text"
            placeholder="Ваше имя *"
            v-model="fieldName"
            :class="{'input-text__input-error': fieldError && v$.fieldName.$invalid, 'input-text__input-complete': !v$.fieldName.$invalid && (fieldName.length > 0)}"
        )
      .input-text
        input.input-text__input(
            type="tel"
            placeholder="Ваш телефон *"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
            v-model="fieldPhone"
            :class="{'input-text__input-error': fieldError && v$.fieldPhone.$invalid, 'input-text__input-complete': !v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
        )
      .consent
        input.consent__input(type="checkbox" id="form-checkbox-catalog" v-model="consent" value="consent" checked="checked")
        label.consent__label(for="form-checkbox-catalog" v-html="formConsentText")

      .button(@click="getSend()")
        .button__text Отправить
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
    name: 'AppFormCatalog',

    setup () {
        return { v$: useVuelidate() }
    },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

    data() {
        return {
          send: false,
          files: '',
          consent: ['consent'],
          fieldName: '',
          fieldPhone: '',
          fieldError: false,
          formRedirect: ''
        }
    },

    methods: {
        ...mapActions([
            'Send'
        ]),

        getSend () {
            if (this.v$.$invalid) {
              this.fieldError = true
            }

            if (!this.v$.$invalid) {
              this.send = !this.send
              document.querySelector('#button-popup-success').click()
              this.Send({
                setting: {
                  title: this.formSettingTitle,
                  subject: this.formSettingSubject
                },

                fields: [
                  {
                      title: 'Имя',
                      value: this.fieldName
                  },
                  {
                      title: 'Телефон',
                      value: this.fieldPhone
                  },
                  {
                    title: this.formFieldPage,
                    value: this.formPagePagetitle + ' (' + this.formPageId + ')'
                  }
                ],
                files: this.files
              })

              this.fieldName = this.fieldPhone = ''
              this.fieldError = false

              if (this.formRedirect) {
                setTimeout(() => {
                  window.location.href = this.formRedirect
                }, 1500)
              } else {
                setTimeout(() => {
                  this.send = !this.send
                }, 4000)
              }
            }
        }
    },

    validations () {
        return {
            fieldName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(40)
            },

            fieldPhone: {
                required,
                minLength: minLength(18),
                maxLength: maxLength(18)
            },

            consent: {
                required
            }
        }
    },

    created () {
      this.formRedirect = window.appPansionat.form.formRedirect
    }
}
</script>
